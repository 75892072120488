import Preloader from "@/components/preloader/preloader";

export default {
	name: 'AdminSideBar',
	components: {
		Preloader
	},
	data () {
		return {
			tab: 0,
			tabs: [
				{
					code: 'clients-logos',
					title: 'Clients logos',
					icon: 'mdi-post',
					to: 'ClientsLogos'
				},
				{
					code: 'services',
					title: 'Services',
					icon: 'mdi-post',
					to: 'AdminServices'
				},
				{
					code: 'projects',
					title: 'Projects',
					icon: 'mdi-post',
					to: 'Projects'
				},
				{
					code: 'contact_submitting_list',
					title: 'Contact submitting list',
					icon: 'mdi-book-information-variant',
					to: 'ContactSubmittingList'
				},
				{
					code: 'position_submitting_list',
					title: 'Position submitting list',
					icon: 'mdi-book-information-variant',
					to: 'PositionSubmittingList'
				},
				{
					code: 'positions',
					title: 'Positions',
					icon: 'mdi-book-information-variant',
					to: 'Positions'
				},
				{
					code: 'team',
					title: 'Team',
					icon: 'mdi-book-information-variant',
					to: 'TeamList'
				},
				{
					code: 'testimonials',
					title: 'Testimonials',
					icon: 'mdi-message-draw',
					to: 'Testimonials'
				},
				{
					code: 'about',
					title: 'About editor',
					icon: 'mdi-message-draw',
					to: 'AdminAbout'
				},
				{
					code: 'settings',
					title: 'Settings',
					icon: 'mdi-cog-outline',
					to: 'Settings'
				}
			],
			active: 'blog_articles'
		}
	},
	computed: {
		currentTabTitle () {
			const title = this.$route.name
			var result = title.replace( /([A-Z])/g, " $1" );
			return  result.charAt(0).toUpperCase() + result.slice(1);
		}
	}
}
