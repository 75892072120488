export default {
  name: 'ConfirmingModal',
  props: ['params'],
  data () {
    return {
      dialog: false,
      text: '',
      title: '',
      close_title: '',
      action_title: '',
      action: null
    }
  },
  mounted () {
    this.$root.$on('ConfirmingModal', (dialog, params) => {
      this.dialog = dialog
      if (params) {
        this.text = params.text ? params.text : 'text'
        this.title = params.title ? params.title : 'title'
        this.close_title = params.close_title ? params.close_title : 'Close'
        this.action_title = params.action_title ? params.action_title : 'Submit'
        this.action = params.action ? params.action : () => console.log('Fail')
      }
    })
  }
}
