'use strict'
import ApiBase from './base'

class ApiBatch extends ApiBase {
	static getAccountInfo(self) {
		self.http('get', `${ApiBase.baseUrl()}/users/current`, null)
			.then(response => {
				ApiBase.setToStore(null, 'setUserDataList', response.data)
			})
	}
}

export default ApiBatch
