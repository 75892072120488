import StorageHelper from '@/helpers/localStorageHelper'
import ApiBatch from "@/api/batch";
import Preloader from "@/components/preloader/preloader";
import AdminSideBar from "@/components/admin-side-bar/AdminSideBar"
import storageHelper from "@/helpers/localStorageHelper";
import ConfirmModal from "@/components/confirming-modal/Modal.vue"

export default {
	name: 'AdminContainer',
	components: {
		Preloader,
		AdminSideBar,
		ConfirmModal
	},
	data() {
		return {
			drawer: null,
		}
	},
	methods: {
		logoutModal() {
			this.$root.$emit('ConfirmingModal', true, {
				title: "Logout",
				text: "Sure logout admin panel?",
				action_title: 'Logout',
				action: () => {
					this.logout()
				}
			})
		},
		logout() {
			StorageHelper.remove('Token')
			this.$router.push({name: 'Home'})
		},
		login() {
			this.$router.push({name: "Login"})
		}
	},
	created() {
		if (storageHelper.get('Token')) {
			ApiBatch.getAccountInfo(this)
		}
	}
}
